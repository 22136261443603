import React, { useState, useEffect } from "react"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates"
import Select from "react-select"
import "../style.css"
import { graphql, Link } from "gatsby"
import {
  Pane,
  Checkbox,
  toaster,
  Spinner,
  TextInputField,
  Button,
} from "evergreen-ui"
import axios from "axios"
import moment from "moment"
import "moment/locale/pl"

moment.locale("pl")

const difficulties = [
  { value: "forBeginner", label: "Początkujacy" },
  { value: "forSemiProfessional", label: "Średniozaawansowany" },
  { value: "forProfessional", label: "Zaawansowany" },
]

const Column = ({ children }) => (
  <Pane flexGrow="1" width="100%" padding="16px">
    {children}
  </Pane>
)

const Order = ({ items, start, end }) => {
  const [email, setEmail] = useState("")
  const [availabile, setAvailable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [closed, setClosed] = useState(false)
  useEffect(() => {
    setLoading(true)
    axios
      .get(
        `https://nurkowie-admin.c.krzysztofsikorski.pl/checkfree?items=${items.join(
          ","
        )}&time=${start.format("YYYY-MM-DD")},${end.format("YYYY-MM-DD")}`
      )
      .then(({ data }) => {
        console.log(data)
        if (data.some(el => el === false)) {
          setAvailable(false)
        } else {
          setAvailable(true)
        }
        setLoading(false)
      })
  }, [items, start, end])

  const orderOne = () => {
    setClosed(true)
    axios
      .get(
        `https://nurkowie-admin.c.krzysztofsikorski.pl/saveOrder?phone=${email}&items=${items.join(
          ","
        )}&time=${start.format("YYYY-MM-DD")},${end.format("YYYY-MM-DD")}`
      )
      .then(({ data }) => {
        toaster.notify("Skontaktujemy się z Tobą niedługo!")
      })
  }
  if (closed) return <Pane></Pane>
  if (loading)
    return (
      <Pane display="flex" justifyContent="center" alignItems="center">
        <Spinner />
      </Pane>
    )
  // TODO: Order API
  return availabile ? (
    <Pane>
      <h1>Zarezerwuj sprzęt!</h1>
      <TextInputField
        height={40}
        label="Podaj numer telefonu, na który zadzownimy by potwierdzić rezerwację"
        placeholder="123 456 789"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Button onClick={orderOne} appearance="primary">
        Zamów
      </Button>
    </Pane>
  ) : (
    <Pane>Wybrany sprzęt nie jest dostępny w tym terminie</Pane>
  )
}

const DeviceList = ({
  allDeviceTypes,
  startDate,
  endDate,
  difficulty,
  checked,
  toggleChecked,
  chosenPlaces,
}) => {
  const tripLength =
    endDate.diff(startDate, "days") >= 7 ? "forLong" : "forShort"

  const filteredDeviceTypes = allDeviceTypes
    .filter(el => el[difficulty.value])
    .filter(el => el[tripLength])
    .filter(el =>
      chosenPlaces.some(place =>
        el.places.map(el => el.id).includes(place.value)
      )
    )

  return (
    <Pane>
      {filteredDeviceTypes.map(device => (
        <Pane display="flex" alignItems="stretch" marginBottom={"32px"}>
          <Pane width="164px" display="flex" alignItems="center">
            <img
              style={{
                width: "100%",
              }}
              src={
                "https://nurkowie-admin.c.krzysztofsikorski.pl" +
                device.image.publicUrl
              }
            />
          </Pane>
          <Pane width="calc(100% - 164px - 32px)" paddingX="32px">
            <h1
              style={{
                marginBottom: "8px",
              }}
            >
              {device.name}
            </h1>
            <Pane>{device.description}</Pane>
          </Pane>
          <Pane width="32px" display="flex" alignItems="center">
            <Checkbox
              checked={device.required || checked.includes(device.id)}
              onChange={e => toggleChecked(device.id)}
              disabled={device.required}
            />
          </Pane>
        </Pane>
      ))}
      <Pane padding="64px" marginBottom="64px">
        <Order
          start={startDate}
          end={endDate}
          items={filteredDeviceTypes
            .filter(el => el.required || checked.includes(el.id))
            .map(el => el.id)}
        />
      </Pane>
    </Pane>
  )
}

export default ({
  data: {
    db: { allPlaces, allDeviceTypes },
  },
}) => {
  const [chosenPlaces, setChosenPlaces] = useState([])
  const [difficulty, setDifficulty] = useState(false)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [focused, setFocused] = useState(null)

  const [checked, setChecked] = useState([])

  const toggleChecked = id => {
    if (!checked.includes(id)) setChecked(checked.concat([id]))
    else setChecked(checked.filter(el => el !== id))
  }

  return (
    <Pane maxWidth="980px" marginX="auto">
      <Pane width="320px" marginX="auto" marginBottom="32px">
        <img
          src="/logo.png"
          style={{
            width: "100%",
          }}
        />
      </Pane>

      <Link to="/poradnik">
        <Pane textAlign="center">Do poradnika</Pane>
      </Link>
      <Pane display="flex" flexDirection="row">
        <Column>
          <Pane marginBottom="8px" fontWeight="bold">
            Miejsce wyjazdu:
          </Pane>
          <Select
            classNamePrefix="bigSelect"
            value={chosenPlaces}
            onChange={setChosenPlaces}
            options={allPlaces.map(el => ({
              label: el.name,
              value: el.id,
            }))}
            placeholder="Wybierz miejsca..."
            isMulti
          />
        </Column>
        <Column>
          <Pane marginBottom="8px" fontWeight="bold">
            Poziom doświadczenia:
          </Pane>
          <Select
            placeholder="Wybierz doświadczenie..."
            classNamePrefix="bigSelect"
            value={difficulty}
            onChange={setDifficulty}
            options={difficulties}
          />
        </Column>
        <Column>
          <Pane marginBottom="8px" fontWeight="bold">
            Termin wyjazdu:
          </Pane>
          <DateRangePicker
            startDate={startDate} // momentPropTypes.momentObj or null,
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
            endDate={endDate} // momentPropTypes.momentObj or null,
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate)
              setEndDate(endDate)
            }}
            focusedInput={focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={setFocused} // PropTypes.func.isRequired,
            startDatePlaceholderText="Data wyjazdu"
            endDatePlaceholderText="Data powrotu"
          />
        </Column>
      </Pane>
      {startDate && endDate && difficulty && chosenPlaces.length && (
        <Pane>
          <DeviceList
            allDeviceTypes={allDeviceTypes}
            startDate={startDate}
            endDate={endDate}
            difficulty={difficulty}
            checked={checked}
            toggleChecked={toggleChecked}
            chosenPlaces={chosenPlaces}
          />
        </Pane>
      )}
    </Pane>
  )
}

export const query = graphql`
  {
    db {
      allPlaces {
        name
        id
      }
      allDeviceTypes {
        id
        forLong
        forBeginner
        forProfessional
        forSemiProfessional
        forShort
        name
        places {
          id
        }
        image {
          publicUrl
        }
        required
        description
      }
    }
  }
`
